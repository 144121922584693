<template>
  <div class="register">
    <h2>New Friends</h2>
    <form @submit.prevent="handleRegister">
      <div>
        <label or="username">ID:  </label>
        <input type="text" id="username" v-model="username" required>
      </div>
      <div>
        <label for="password">Password: </label>
        <input type="password" id="password" v-model="password" required>
      </div>
      <div>
        <label for="email">Email: </label>
        <input type="email" id="email" v-model="email">
      </div>
      <button type="submit">Register</button>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'RegisterPage',
  setup() {
    const store = useStore()
    const router = useRouter()
    const username = ref('')
    const email = ref('')
    const password = ref('')

    const handleRegister = async () => {
      try {
        await store.dispatch('register', {
          username: username.value,
          email: email.value,
          password: password.value
        })
        router.push('/login')
      } catch (error) {
        console.error('Registration failed:', error)
      }
    }

    return {
      username,
      email,
      password,
      handleRegister
    }
  }
}
</script>