<template>
  <div class="home">
    <h1>Welcome to the Livestream App</h1>
    <div v-if="isLoggedIn">
      <router-link to="/stream">Go to Stream</router-link>
    </div>
    <div v-else>
      <router-link to="/login">Login</router-link> |
      <router-link to="/register">Register</router-link>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'HomePage',
  setup() {
    const store = useStore()
    const isLoggedIn = computed(() => store.getters.isLoggedIn)

    return {
      isLoggedIn
    }
  }
}
</script>