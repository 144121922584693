<template>
  <h1>Welcome to Ian's Playroom 👶🏻</h1>
  <div class="stream">
    <div v-if="loveSent" class="love-notification">
      Love sent! ❤️
    </div>
    <div class="stream-container">
      <div class="video-container">
        <div v-if="streamUrl">
          <iframe :src="streamUrl" frameborder="no" scrolling="no" allowfullscreen="true"></iframe>
        </div>
        <div v-else>
          <p>Loading stream...</p>
        </div>
      </div>
      <div class="chat-container">
        <div class="chat">
          <div class="chat-messages" ref="chatMessagesContainer">
            <div v-for="message in chatMessages" :key="message.id" class="chat-message">
              <span class="username"><b>{{ message.username }}: </b></span>
              <span class="message-text">{{ message.text }}</span>
            </div>
          </div>
          <div v-if="isLoggedIn" class="chat-input">
            <input v-model="newMessage" @keyup.enter="sendMessage" placeholder="Type a message...">
            <button class="send-button" @click="sendMessage">Send</button>
          </div>
          <div v-else>
            <p>Please <router-link to="/login">Login</router-link> to participate in the chat.</p>
          </div>
          <button class="love-button" @click="sendLove">Love</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, onUnmounted} from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import io from 'socket.io-client'

axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'http://localhost:8080';

export default {
  name: 'StreamPage',
  setup() {
    const store = useStore()
    const streamUrl = ref('')
    const chatMessages = ref([])
    const newMessage = ref('')
    const loveSent = ref(false)
    const socket = io(axios.defaults.baseURL)

    const MAX_MESSAGES = 18;

    const addMessage = (message) => {
      chatMessages.value.push(message);
      if (chatMessages.value.length > MAX_MESSAGES) {
        chatMessages.value.shift(); // Remove the oldest message
      }
    };

    const isLoggedIn = computed(() => store.getters.isLoggedIn)

    const fetchRecentMessages = async () => {
      try {
        const response = await axios.get('/api/chat/recent')
        chatMessages.value = response.data
      } catch (error) {
        console.error('Error fetching recent messages:', error)
      }
    }

    onMounted(async () => {
      try {
        const response = await axios.get('/stream_url')
        streamUrl.value = response.data.stream_url
        await fetchRecentMessages()

        // socket.on('new_chat_message', (message) => {
        //   chatMessages.value.push(message)
        //   scrollToBottom()
        // })
        socket.on('new_chat_message', addMessage)


        socket.on('love_sent', () => {
          loveSent.value = true
          setTimeout(() => {
            loveSent.value = false
          }, 3000)
        })
      } catch (error) {
        console.error('Failed to fetch stream URL:', error)
      }
    })

    onUnmounted(() => {
      socket.disconnect()
    })

    const sendMessage = async () => {
      if (newMessage.value.trim() && isLoggedIn.value) {
        try {
          const token = store.state.token
          await axios.post('/api/chat', { message: newMessage.value }, {
            headers: { Authorization: `Bearer ${token}` }
          })
          newMessage.value = ''
        } catch (error) {
          console.error('Error sending message:', error)
          if (error.response && error.response.status === 401) {
            console.error('Authentication failed. Logging out.')
            await store.dispatch('logout')
            // Redirect to login page or show login modal
          }
        }
      } else if (!isLoggedIn.value) {
        console.error('User not logged in. Please log in to send messages.')
        // Redirect to login page or show login modal
      }
    }

    const sendLove = async () => {
      if (isLoggedIn.value) {
        try {
          const token = store.state.token
          await axios.post('/send_love',{ message: "" },{
              headers: { Authorization: `Bearer ${token}` }
            })
          console.log('Love sent!')
        } catch (error) {
          console.error('Failed to send love:', error)
        }
      }
      else if (!isLoggedIn.value) {
        console.error('User not logged in. Please log in to send love.')
        // Redirect to login page or show login modal
      }
   }

    return {
      streamUrl,
      chatMessages,
      newMessage,
      sendMessage,
      sendLove,
      isLoggedIn,
      loveSent
    }
  }
}
</script>

<style scoped>
.stream {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.stream-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

/* .video-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
} */

.video-container {
  width: 100%;
  height: 0;
  padding-bottom: 65%; /*16:9 Aspect Ratio */
  margin: 0 auto;
  position: relative;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  /* min-height: 640px; */
}

.chat-container {
  width: 100%;
  height: 500px;
  max-width: 1200px;
  margin: 10px auto;
}

.chat {
  height: 500px; /* Fixed height for chat */
  display: flex;
  flex-direction: column;
}

.chat-messages {
  /* flex-grow: 1;
  overflow-y: auto; */
  position: relative;
  top: 0;
  left: 0;
  height: 400px;
  border: 1px solid #ccc;
  background-color: blanchedalmond;
  padding: 10px;
  margin-bottom: 10px;
}

.chat-input {
  display: flex;
  height: 40px;
}

.chat-input input {
  flex-grow: 1;
  margin-right: 10px;
  background-color: blanchedalmond;
  border-color: aqua;
}

.love-notification {
  background-color: #ff69b4;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
}

button {
  background-color: #00a6ff;
}

.love-button {
  height: 40px;
  padding: 0 10px;
  margin-top:10px;
  background-color: #f692e1;
  border-color: aqua;
}

@media (min-width: 1024px) {
  .stream-container {
    flex-direction: row;
    align-items: flex-start;
  }

  .video-container {
    flex: 1 1 640px;
  }

  .chat-container {
    flex: 1 1 300px;
    max-width: 400px;
  }
}

</style>
